<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="HAZARD_CLASS_FST_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hazardClassFstCd"
            label="유해인자 대분류"
            v-model="searchParam.hazardClassFstCd"
            @datachange="datachange"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :comboItems="occSecItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="hazardClassSecCd"
            label="유해인자 분류"
            v-model="searchParam.hazardClassSecCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="workFlagItems"
            label="작업환경관련여부"
            itemText="codeName"
            itemValue="code"
            name="workEnvFlag"
            type="search"
            v-model="searchParam.workEnvFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="workFlagItems"
            label="특수건강검진관련여부"
            itemText="codeName"
            itemValue="code"
            name="speHealthFlag"
            type="search"
            v-model="searchParam.speHealthFlag">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            :comboItems="useFlagItems"
            label="사용여부"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            type="search"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="hazard"
      title="유해인자 목록"
      tableId="guideBook"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="hazardCd"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'harmful-factors',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'hazardClassFstName',
            field: 'hazardClassFstName',
            label: '유해인자 대분류',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'hazardClassSecName',
            field: 'hazardClassSecName',
            label: '유해인자 분류',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자명',
            align: 'left',
            style: 'width:400px',
            type: "link",
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'Cas No.',
            align: 'center',
            style: 'width:140px',
            sortable: true,
          },
          // {
          //   name: 'chemNmKrs',
          //   field: 'chemNmKrs',
          //   label: '관련화학물질명',
          //   align: 'center',
          //   sortable: true,
          // },
          {
            name: 'workMeasCycleName',
            field: 'workMeasCycleName',
            label: '작업환경측정 주기',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'factorMeasCycleName',
            field: 'factorMeasCycleName',
            label: '유해인자측정 주기',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'workEnvFlagName',
            field: 'workEnvFlagName',
            label: '작업환경관련여부',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'speHealthFlagName',
            field: 'speHealthFlagName',
            label: '특수건강검진 관련 여부',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'useFlagName',
            field: 'useFlagName',
            label: '사용여부',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        hazardClassFstCd: null,
        hazardClassSecCd: null,
        useFlag: 'Y',
        workEnvFlag: null,
        speHealthFlag: null,
      },
      useFlagItems: [],
      workFlagItems: [],
      occSecItems: [],
      period: [ '', ''],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.useFlagItems = [
        { code: 'Y', codeName: this.$language('사용') },
        { code: 'N', codeName: this.$language('미사용') },
      ]
      this.workFlagItems = [
        { code: 'Y', codeName: this.$language('관련') },
        { code: 'N', codeName: this.$language('미관련') },
      ]
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.hazard.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPopup(row);
    },
    datachange() {
      this.setSecondItems(this.searchParam.hazardClassFstCd);
    },
    setSecondItems(hazardClassFstCd) {
      if (hazardClassFstCd){
        this.$http.url = this.$format(selectConfig.sys.code.mst.list.attr.url, 'HAZARD_CLASS_SEC_CD', hazardClassFstCd);
      } else {
        this.$http.url = this.$format(selectConfig.sys.code.mst.list.attr.url, 'HAZARD_CLASS_SEC_CD');
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.occSecItems = [];
        this.searchParam.hazardClassSecCd = null;
        this.$_.forEach(_result.data, _item => {
          this.occSecItems.push({
            code: _item.code,
            codeName: _item.codeName,
          })
        })
      },);
    },
    openPopup(result) {
      this.popupOptions.target = () => import(`${"./harmfulFactorsDetail.vue"}`);
      this.popupOptions.title = '유해인자 상세';
      this.popupOptions.param = {
        hazardCd: result ? result.hazardCd : '',
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
